import { Link } from 'react-router-dom';
import { IMAGES } from '../../utils/staticJSON';
const InnerContent = () => {
  return (
    <div className="p-13 py-lg-12 py-xl-15">
      <div className="row text-white gx-0 gy-10">
        <div className="col-md-10 offset-lg-0 col-lg-6 col-xl-6">
          <h2 className="display-3 fs-45 fw-bold mb-2 text-white text-start">
            Groceries at your doorstep, not in your To-Do List!
          </h2>
          <p className="lead fs-18 fw-500">
            U-Mart: Groceries at your doorstep—because convenience should be on
            your side, not your to-do list.
          </p>
          <div className="d-flex flex-row gap-3">
            <a
              href="#"
              target="_blank"
            >
              <img
                className="img-fluid"
                width={158}
                height={50}
                src={IMAGES.appleStore}
                alt=""
              />
            </a>
            <a
              href="#"
              target="_blank"
            >
              <img
                className="img-fluid"
                width={158}
                height={50}
                src={IMAGES.googleStore}
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
const DownloadNow = () => {
  return (
    <>
      <div
        id="download-selction"
        className="wrapper image-wrapper bg-full bg-image bg-image-none bg-primary rounded-4 d-md-block d-none"
        style={{
          backgroundImage: `url(${IMAGES.downloadUmart})`,
          backgroundPositionY: 'top center',
        }}
      >
        <InnerContent />
      </div>
      <div className="wrapper bg-primary rounded-4 d-md-none">
        <InnerContent />
      </div>
    </>
  );
};
export default DownloadNow;
