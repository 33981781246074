import { IMAGES } from '../utils/staticJSON';
const OurFeatures = () => {
  return (
    <>
      <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center text-center">
        <h1 className="display-1 fs-45 mx-lg-n10 mx-xl-0">Our features</h1>
        <div className=" col-lg-4 order-lg-1">
          <div className="mb-6">
            <h1 className="display-1 fs-75 mx-lg-n10 mx-xl-0 mb-5 text-primary">
              01
            </h1>
            <div
              className="d-inline-block w-100"
              style={{ maxWidth: '275px' }}
            >
              <span className="d-block fs-18 fw-bold mb-2">
                Quick & easy ordering
              </span>
              <span className="fs-18 d-block">
                Orders processed instantly, easy access. U-Mart makes food
                shopping easy by optimizing your experience with rapid ordering
                and seamless accessibility.
              </span>
            </div>
          </div>
          <div className="mb-6">
            <h1 className="display-1 fs-75 mx-lg-n10 mx-xl-0 mb-5 text-primary">
              02
            </h1>
            <div
              className="d-inline-block w-100"
              style={{ maxWidth: '275px' }}
            >
              <span className="d-block fs-18 fw-bold mb-2">
                Extensive product selection
              </span>
              <span className="fs-18 d-block">
                Explore your options at U-Mart. Our wide range of products
                guarantees that you will discover precisely what you need,
                making your shopping experience unique.
              </span>
            </div>
          </div>
          <div className="mb-6">
            <h1 className="display-1 fs-75 mx-lg-n10 mx-xl-0 mb-5 text-primary">
              03
            </h1>
            <div
              className="d-inline-block w-100"
              style={{ maxWidth: '275px' }}
            >
              <span className="d-block fs-18 fw-bold mb-2">
                Customer support
              </span>
              <span className="fs-18 d-block">
                Your satisfaction is our priority at U-Mart. Experience
                unmatched customer support—prompt, friendly, and dedicated to
                ensuring your shopping journey is seamless and delightful.
              </span>
            </div>
          </div>
        </div>
        <div className="col-lg-4 order-lg-2">
          <img
            className="img-fluid"
            src={IMAGES.features as any}
            height="750px"
            width="375px"
            alt=""
          />
        </div>
        <div className="col-lg-4 order-lg-3">
          <div className="mb-6">
            <h1 className="display-1 fs-75 mx-lg-n10 mx-xl-0 mb-5 text-primary">
              04
            </h1>
            <div
              className="d-inline-block w-100"
              style={{ maxWidth: '275px' }}
            >
              <span className="d-block fs-18 fw-bold mb-2">
                Delivery tracking
              </span>
              <span className="fs-18 d-block">
                Use U-Mart’s delivery tracking to stay informed. Easily track
                the progress of your order in real time, providing assurance and
                anticipation for your comfort.
              </span>
            </div>
          </div>
          <div className="mb-6">
            <h1 className="display-1 fs-75 mx-lg-n10 mx-xl-0 mb-5 text-primary">
              05
            </h1>
            <div
              className="d-inline-block w-100"
              style={{ maxWidth: '275px' }}
            >
              <span className="d-block fs-18 fw-bold mb-2">
                Referral program
              </span>
              <span className="fs-18 d-block">
                U-Mart’s referral program might help you earn incentives! Get
                coins for your next purchase when you and your friends enjoy
                hassle-free shopping together.
              </span>
            </div>
          </div>
          <div className="mb-6">
            <h1 className="display-1 fs-75 mx-lg-n10 mx-xl-0 mb-5 text-primary">
              06
            </h1>
            <div
              className="d-inline-block w-100"
              style={{ maxWidth: '275px' }}
            >
              <span className="d-block fs-18 fw-bold mb-2">Order history</span>
              <span className="fs-18 d-block">
                Explore your past purchases and preferences effortlessly with
                U-Mart’s intuitive Order History feature. Reorder with ease and
                relive the convenience.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OurFeatures;
