import { Link } from 'react-router-dom';
import { IMAGES } from '../utils/staticJSON';
const Footer = () => {
  return (
    <footer>
      <div className="bg-primary text-white">
        <div className="container py-9 pb-6 py-md-4 pb-md-4">
          <div className="row gy-6 gy-lg-0">
            <div className="d-md-flex align-items-center justify-content-center">
              <p className="mb-2 mb-lg-0">
                @ 2023 All Rights Reserved | Terms & Conditions
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bg-primary text-white">
        <div className="container">
          <div className="row gy-6 p-4 p-md-4 gy-lg-0">
            <div className="d-md-flex align-items-center justify-content-center">
              <p className="mb-2 mb-lg-0">
                @ 2023 All Rights Reserved | Terms & Conditions
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </footer>
  );
};
export default Footer;
