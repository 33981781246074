import { IMAGES } from '../../utils/staticJSON';
import { Splide, SplideSlide } from '@splidejs/react-splide';
// splider >> CSS
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/sea-green';
import '@splidejs/react-splide/css/core';
const Testimonials = () => {
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <h2 className="display-4 fs-45 fw-bold mb-2 text-center">
            Customer delights
          </h2>
          <p className="lead fs-18 fw-500 mb-2 text-center">
            See what our valued customers are saying about their experiences
            with us. Their feedback reflects the heart of our mission: to
            deliver not only quality groceries but also unparalleled service.
          </p>
        </div>
        <div className="col-lg-12">
          <Splide
            className="px-0 py-md-8 py-6"
            options={{
              arrows: false,
              height: 'auto',
              autoplay: true,
              rewind: true,
              gap: '1rem',
              perPage: 3,
              pagination: false,
              breakpoints: {
                640: {
                  perPage: 1,
                  pagination: true,
                },
                767: {
                  perPage: 2,
                  pagination: true,
                },
                992: {
                  perPage: 1,
                  pagination: true,
                },
                1024: {
                  perPage: 2,
                  pagination: true,
                },
              },
              updateOnMove: true,
            }}
          >
            <SplideSlide>
              <div className="card testimonials bg-fcf3e6 mb-6">
                <div className="card-body px-6 pt-6 pb-0">
                  <blockquote className="border-0 mb-0">
                    <img
                      className="img-fluid"
                      src={IMAGES.whiteQuote as any}
                      alt=""
                    />
                    <p className="fs-18 text-black">
                      UMart is a game-changer for me. It’s more than just an
                      app; it’s become an integral part of my routine. It’s easy
                      to use, they have good deals, and there’s a lot of stuff
                      to choose from. U-Mart is my absolute favorite for
                      groceries, and it makes shopping stress-free and good. The
                      variety and ease of use have made my life so much simpler,
                      and I find myself turning to U-Mart for all my grocery
                      needs.”
                    </p>
                  </blockquote>
                </div>
                <div className="card-footer border-top-0 pt-0 px-6 pb-10">
                  <div className="info">
                    <h5 className="fs-16 mb-0">Rafiki Ng’ambi</h5>
                  </div>
                </div>
              </div>
              <div className="card testimonials bg-ecf3ef">
                <div className="card-body px-6 pt-6 pb-0">
                  <blockquote className="border-0 mb-0">
                    <img
                      className="img-fluid"
                      src={IMAGES.whiteQuote as any}
                      alt=""
                    />
                    <p className="fs-18 text-black">
                      U-Mart made shopping so much easier for me, and I couldn’t
                      be happier. The app is simple, and they bring my stuff
                      fast with good discounts. U-Mart is my top choice for
                      groceries. It’s a really good place to shop, and the
                      convenience it adds to my life is remarkable. It’s become
                      a trusted companion in my daily routine, making shopping
                      both easy and enjoyable.
                    </p>
                  </blockquote>
                </div>
                <div className="card-footer border-top-0 pt-0 px-6 pb-10">
                  <div className="info">
                    <h5 className="fs-16 mb-0">Bianac Rose</h5>
                  </div>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide className="my-10">
              <div className="card testimonials bg-f7e6e7 mb-6">
                <div className="card-body px-6 pt-6 pb-0">
                  <blockquote className="border-0 mb-0">
                    <img
                      className="img-fluid"
                      src={IMAGES.whiteQuote as any}
                      alt=""
                    />
                    <p className="fs-18 text-black">
                      UMart won our hearts, and I can’t emphasize enough how
                      it’s become our go-to for groceries. The deals are good,
                      and they bring our groceries fast. U-Mart is our family’s
                      absolute favorite for groceries, and it makes getting what
                      we need easy and quick. The reliability and efficiency
                      have made it a constant in our weekly routine, and we
                      appreciate the convenience it adds to our busy lives.
                    </p>
                  </blockquote>
                </div>
                <div className="card-footer border-top-0 pt-0 px-6 pb-10">
                  <div className="info">
                    <h5 className="fs-16 mb-0">Joseph Mwita</h5>
                  </div>
                </div>
              </div>
              <div className="card testimonials bg-e2e9f8">
                <div className="card-body px-6 pt-6 pb-0">
                  <blockquote className="border-0 mb-0">
                    <img
                      className="img-fluid"
                      src={IMAGES.whiteQuote as any}
                      alt=""
                    />
                    <p className="fs-18 text-black">
                      U-Mart is a real lifesaver! I can’t stress enough how much
                      it has changed my routine. They bring my groceries super
                      fast, and the stuff they send is always fresh and good.
                      It’s easy to use, and I love how it makes shopping simple.
                      U-Mart is my absolute favorite for getting everything I
                      need, and I appreciate the convenience it brings to my
                      busy days.
                    </p>
                  </blockquote>
                </div>
                <div className="card-footer border-top-0 pt-0 px-6 pb-10">
                  <div className="info">
                    <h5 className="fs-16 mb-0">Samira Hassan</h5>
                  </div>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="card testimonials bg-ecf3ef mb-6">
                <div className="card-body px-6 pt-6 pb-0">
                  <blockquote className="border-0 mb-0">
                    <img
                      className="img-fluid"
                      src={IMAGES.whiteQuote as any}
                      alt=""
                    />
                    <p className="fs-18 text-black">
                      U-Mart has truly changed how I shop, and I’m quite
                      impressed. The app is easy to navigate, and the deals are
                      genuinely great. It’s my go-to for everything from food
                      basics to fresh stuff. U-Mart makes shopping easy and
                      fun—I really like it! The simplicity and great deals make
                      my life so much simpler, and I’m grateful for the
                      convenience it adds to my everyday tasks.
                    </p>
                  </blockquote>
                </div>
                <div className="card-footer border-top-0 pt-0 px-6 pb-10">
                  <div className="info">
                    <h5 className="fs-16 mb-0">Yash Dayama</h5>
                  </div>
                </div>
              </div>
              <div className="card testimonials bg-f7efe7 ">
                <div className="card-body px-6 pt-6 pb-0">
                  <blockquote className="border-0 mb-0">
                    <img
                      className="img-fluid"
                      src={IMAGES.whiteQuote as any}
                      alt=""
                    />
                    <p className="fs-18 text-black">
                      UMart has not just won us over; it's become an integral
                      part of our family's grocery routine. The deals are
                      top-notch, and the speedy delivery of our groceries is a
                      game-changer. U-Mart has earned its spot as our family's
                      favorite for groceries, making it effortless to get
                      everything we need quickly. The reliability and efficiency
                      have turned it into a constant in our weekly schedule, and
                      we're thankful for the convenience it brings to our busy
                      lives. UMart truly stands out as a reliable ally in our
                      household.
                    </p>
                  </blockquote>
                </div>
                <div className="card-footer border-top-0 pt-0 px-6 pb-10">
                  <div className="info">
                    <h5 className="fs-16 mb-0">Chetan Ukani</h5>
                  </div>
                </div>
              </div>
            </SplideSlide>
            <SplideSlide className="my-10">
              <div className="card testimonials bg-e2e9f8  mb-6">
                <div className="card-body px-6 pt-6 pb-0">
                  <blockquote className="border-0 mb-0">
                    <img
                      className="img-fluid"
                      src={IMAGES.whiteQuote as any}
                      alt=""
                    />
                    <p className="fs-18 text-black">
                      UMart is more than I expected, and it has made my grocery
                      shopping experience truly delightful. The food is fresh,
                      they bring it on time, and the convenience is unmatched.
                      U-Mart is my go-to for groceries, and it’s a good place
                      for quality and convenience. The consistency in service
                      and the quality of products have made it a reliable choice
                      for me.
                    </p>
                  </blockquote>
                </div>
                <div className="card-footer border-top-0 pt-0 px-6 pb-10">
                  <div className="info">
                    <h5 className="fs-16 mb-0">Jamani Boro</h5>
                  </div>
                </div>
              </div>
              <div className="card testimonials bg-f7e6e7">
                <div className="card-body px-6 pt-6 pb-0">
                  <blockquote className="border-0 mb-0">
                    <img
                      className="img-fluid"
                      src={IMAGES.whiteQuote as any}
                      alt=""
                    />
                    <p className="fs-18 text-black">
                      U-Mart has revolutionized my shopping experience, and I
                      can't help but sing its praises. Navigating through the
                      app is a breeze, and the deals are genuinely fantastic.
                      From everyday food basics to fresh produce, U-Mart is my
                      one-stop-shop. The simplicity and great deals make my life
                      so much easier, turning shopping into a genuinely
                      enjoyable task. I'm genuinely grateful for the convenience
                      it seamlessly adds to my daily routine.
                    </p>
                  </blockquote>
                </div>
                <div className="card-footer border-top-0 pt-0 px-6 pb-10">
                  <div className="info">
                    <h5 className="fs-16 mb-0">Isaac Chukweze</h5>
                  </div>
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </div>
    </>
  );
};
export default Testimonials;
