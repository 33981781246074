import { IMAGES } from '../utils/staticJSON';
import Testimonials from './components/testimonials';
import DownloadNow from './components/download-now';
import Contact from './contact';
import OurFeatures from './our-features';
const Home = () => {
  return (
    <>
      <div className="content-wrapper">
        {/* Home Section */}
        <section
          id="home"
          className="wrapper gradient-background"
        >
          <div className="container pt-12 pt-md-12 pb-11 pb-md-19 pb-lg-10 text-center">
            <div className="row">
              <div className="col-lg-10 col-xl-11 col-xxl-8 mx-auto">
                <h1 className="display-1 fs-65 mx-lg-n10 mx-xl-0 mb-2">
                  Bring the store to your door
                </h1>
                <p className="lead fs-18 px-md-12 px-lg-0 mx-lg-n10 mx-xl-0 mb-8">
                  Click, Relax, Enjoy! U-Mart transforms shopping into an
                  effortless delight. Quality essentials, one click away!
                </p>
                <div className="d-flex justify-content-center flex-row gap-3">
                  <a
                    href="#"
                    target="_blank"
                  >
                    <img
                      className="img-fluid"
                      width={158}
                      height={50}
                      src={IMAGES.appleStore}
                      alt=""
                    />
                  </a>
                  <a
                    href="#"
                    target="_blank"
                  >
                    <img
                      className="img-fluid"
                      width={158}
                      height={50}
                      src={IMAGES.googleStore}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 ms-auto mb-6">
            <figure>
              <img
                className="img-fluid"
                src={IMAGES.vegetable}
                alt=""
              />
            </figure>
          </div>
        </section>
        <section
          id="welcome"
          className="wrapper py-md-8 py-6"
        >
          <div className="bg-white">
            <div className="container pt-3 pb-9">
              <div className="row gx-0 gy-10 align-items-center text-center text-lg-start">
                <div className="col-lg-6 col-xxl-5 order-lg-1 order-1">
                  <figure>
                    <img
                      className="img-fluid"
                      src={IMAGES.welcomeShopping}
                      alt=""
                    />
                  </figure>
                </div>
                <div className="col-lg-6 ms-auto order-lg-2">
                  <h1 className="display-1 fs-55 fw-bold mb-6">
                    Welcome to U-Mart
                  </h1>
                  <p className="lead fs-18 ">
                    Step into the extraordinary at U-Mart, where top-notch
                    service meets your convenience. With lightning-fast same-day
                    delivery and an extensive array of categories, we're
                    reshaping your shopping journey.
                  </p>
                  <p className="lead fs-18 ">
                    Explore a world of premium groceries, where variety and
                    speed converge seamlessly. Elevate your experience—choose
                    U-Mart for a shopping adventure that’s as unique as you are.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Home Features */}
        <section
          id="features"
          className="container-background"
        >
          <div className=" container py-md-8 py-6 ">
            <OurFeatures />
          </div>
        </section>
        {/* Why shooes umart */}
        <section
          id="why-choose-umart"
          className="container py-md-8 py-6"
        >
          <div className="row text-center">
            <div className="col-md-11 col-lg-11 col-xl-10 col-xxl-8 mx-auto">
              <h2 className="display-4 fs-45 fw-bold mb-10 text-center">
                Why choose U-mart?
              </h2>
            </div>
          </div>
          <div className="row gx-md-6 gy-5 text-center">
            <div className="col-md-6 col-xl-3">
              <div className="card bg-e4f4e7 pt-2 pt-md-4">
                <div className="card-body">
                  <img
                    className="img-fluid mb-5"
                    src={IMAGES.quality}
                    height={90}
                    width={90}
                    alt=""
                  />
                  <h3 className="fs-18 fw-bold mb-3">Quality products</h3>
                  <p className="fs-18 text-black">
                    Elevate your experience with a curated selection of quality
                    products.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card bg-e4f4e7 pt-2 pt-md-4">
                <div className="card-body">
                  <img
                    className="img-fluid mb-5"
                    src={IMAGES.easyOrder}
                    height={90}
                    width={90}
                    alt=""
                  />
                  <h3 className="fs-18 fw-bold mb-3">Easy to order</h3>
                  <p className="fs-18 text-black">
                    Simplify your shopping experience: Easy ordering, just a few
                    clicks away.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card bg-e4f4e7 pt-2 pt-md-4">
                <div className="card-body">
                  <img
                    className="img-fluid mb-5"
                    src={IMAGES.fastDelivery}
                    height={90}
                    width={90}
                    alt=""
                  />
                  <h3 className="fs-18 fw-bold mb-3">Fast Delivery</h3>
                  <p className="fs-18 text-black">
                    Fast, secure deliveries via eco-friendly EVs for swift,
                    sustainable service.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card bg-e4f4e7 pt-2 pt-md-4">
                <div className="card-body">
                  <img
                    className="img-fluid mb-5"
                    src={IMAGES.offersDiscount}
                    height={90}
                    width={90}
                    alt=""
                  />
                  <h3 className="fs-18 fw-bold mb-3">Offers & Discounts</h3>
                  <p className="fs-18 text-black">
                    Unlock exclusive savings at your fingertips with our latest
                    offers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Testimonial */}
        <section
          id="testimonial"
          className="pt-md-8 pt-6"
        >
          <div className="container">
            <Testimonials />
          </div>
        </section>
        <section className="container py-md-8 py-6">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-5">
              <img
                className="img-fluid"
                src={IMAGES.delivery as any}
                alt=""
              />
            </div>
            <div className="col-lg-7">
              <h3 className="display-4 fs-45 fw-bold text-black mb-md-6 mb-4">
                Fast Grocery Delivery, Zero <br />
                Emissions!
              </h3>
              <div className="blockquote-details">
                <p className="lead d-flex align-items-start">
                  <img
                    className="img-fluid pt-1"
                    src={IMAGES.check}
                    alt=""
                  />
                  <span className="fs-18 ms-3">
                    Swift grocery delivery: Experience quick and efficient
                    service at U-Mart.
                  </span>
                </p>
              </div>
              <div className="blockquote-details">
                <p className="lead d-flex align-items-start">
                  <img
                    className="img-fluid pt-1"
                    src={IMAGES.check}
                    alt=""
                  />
                  <span className="fs-18 ms-3">
                    Zero emissions commitment: We prioritize a greener future
                    for a sustainable shopping experience.
                  </span>
                </p>
              </div>
              <div className="blockquote-details">
                <p className="lead d-flex align-items-start">
                  <img
                    className="img-fluid pt-1"
                    src={IMAGES.check}
                    alt=""
                  />
                  <span className="fs-18 ms-3">
                    Cleaner, eco-friendly deliveries: Enjoy the convenience
                    without compromising the environment.
                  </span>
                </p>
              </div>
              <div className="blockquote-details">
                <p className="lead d-flex align-items-start">
                  <img
                    className="img-fluid pt-1"
                    src={IMAGES.check}
                    alt=""
                  />
                  <span className="fs-18 ms-3">
                    Contributing to a healthier planet: Support our mission for
                    a more sustainable and cleaner Earth.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="container py-md-8 py-6 mb-14">
          <DownloadNow />
        </section>
        {/* Contact us */}
        <section
          id="contact-us"
          className="wrapper gradient-background"
        >
          <div className="container">
            <Contact />
          </div>
        </section>
      </div>
    </>
  );
};
export default Home;
