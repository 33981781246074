import { IMAGES } from '../utils/staticJSON';
import { Button, Offcanvas } from 'react-bootstrap';
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
interface HeaderProps {
  showButton: boolean;
}
export const Header: React.FC<HeaderProps> = ({ showButton }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const location = useLocation();
  const isContactUsPage = location.pathname === './contact-us';
  const [setButton, setShowButton] = useState(false);
  return (
    <header className="wrapper bg-light">
      <nav className="navbar navbar-expand-lg center-nav navbar-light navbar-bg-light">
        <div className="container flex-lg-row flex-nowrap align-items-center">
          <div className="navbar-brand">
            <NavLink to="/">
              <img
                className=""
                src={IMAGES.umartLogo}
                alt=""
              />
            </NavLink>
          </div>
          <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a
                  href="#home"
                  className="nav-link text-black"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#welcome"
                  className="nav-link text-black"
                >
                  About us
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#features"
                  className="nav-link text-black"
                >
                  Our features
                </a>
              </li>
              <li className="nav-item text-black">
                <a
                  href="#why-choose-umart"
                  className="nav-link text-black"
                >
                  Why choose U-Mart
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#testimonial"
                  className="nav-link text-black"
                >
                  Testimonials
                </a>
              </li>
            </ul>
          </div>
          <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
            <Offcanvas
              show={show}
              onHide={handleClose}
            >
              <Offcanvas.Header
                className="offcanvas-header"
                closeButton
              >
                <Link to="/">
                  <img
                    className="img-fluid"
                    width={125}
                    height={125}
                    src={IMAGES.utradeLogoWhite}
                    alt=""
                  />
                </Link>
                <button
                  type="button"
                  className="btn-close btn-close-white text-white"
                  onClick={handleShow}
                />
              </Offcanvas.Header>
              <Offcanvas.Body className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a
                      href="#home"
                      className="nav-link"
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#welcome"
                      className="nav-link"
                    >
                      About us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#features"
                      className="nav-link"
                    >
                      Our features
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#why-choose-umart"
                      className="nav-link"
                    >
                      Why choose U-Mart
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#testimonial"
                      className="nav-link"
                    >
                      Testimonials
                    </a>
                  </li>
                  <li className="nav-item">
                    {showButton && (
                      <a
                        href="#contact-us"
                        className="nav-link"
                      >
                        Contact us
                      </a>
                    )}
                  </li>
                </ul>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
          <div className="navbar-other w-100 d-flex ms-auto">
            <ul className="navbar-nav flex-row align-items-center ms-auto">
              <li className="nav-item d-none d-md-block">
                {showButton && (
                  <a
                    href="#contact-us"
                    className="btn btn-primary"
                  >
                    Contact us
                  </a>
                )}
              </li>
              <li className="nav-item d-lg-none">
                <button
                  className="hamburger offcanvas-nav-btn"
                  onClick={handleShow}
                >
                  <span />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
export default Header;
