import { Route, Routes, useLocation } from 'react-router-dom';
import Home from '../pages/home';
import { useEffect } from 'react';
const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};
const AppRoutes = () => (
  <>
    <ScrollToTop />
    <Routes>
      <Route
        path="/"
        element={<Home />}
      />
    </Routes>
  </>
);
export default AppRoutes;
