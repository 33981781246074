import clsx from 'clsx';
import { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { IMAGES } from '../utils/staticJSON';
import { success } from '../Global/toast';
import APICallService from '../api/apiCallService';
import { inquiresJSON } from '../api/apiJSON/contactInquires';
import { AddFeedback } from '../api/apiEndPoints';
const Contact = () => {
  const [loading, setLoading] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const staticText = 'Hello, Mohammed';
  const [validation, setValidation] = useState<{
    name: boolean;
    email: boolean;
    emailRegex: boolean;
    phone: boolean;
    message: boolean;
  }>({
    name: false,
    email: false,
    emailRegex: false,
    phone: false,
    message: false,
  });
  const [contactData, setContactData] = useState<{
    name: string;
    email: string;
    phone: string;
    phoneCountry: string;
    message: string;
  }>({
    name: '',
    email: '',
    phone: '',
    message: staticText,
    phoneCountry: '+255',
  });
  const handleDetailsChange = (name: string, value: string) => {
    let temp: any = { ...contactData };
    let tempValidation: any = { ...validation };
    if (name === 'email') {
      tempValidation.emailRegex = false;
    }
    tempValidation[name] = false;
    if (name == 'message') {
      if (value.length >= 15) {
        temp['message'] = value.trimStart();
      } else {
        temp['message'] = staticText;
        tempValidation[name] = true;
      }
    } else {
      if (!value.length) tempValidation[name] = true;
      temp[name] = value.trimStart();
    }
    setContactData(temp);
    setValidation(tempValidation);
  };
  const handleSubmit = async () => {
    let temp = { ...contactData };
    let validateTemp = { ...validation };
    if (temp.name === '') {
      validateTemp.name = true;
    }
    if (temp.email === '') {
      validateTemp.email = true;
    } else {
      if (isValidEmail(temp.email)) {
        console.log('Email is valid.');
      } else {
        validateTemp.emailRegex = true;
      }
    }
    if (temp.phone === '') {
      validateTemp.phone = true;
    }
    if (!(temp.message.trim().length > 15)) {
      validateTemp.message = true;
    }
    console.log(validateTemp);
    if (Object.values(validateTemp).every((val) => val === false)) {
      setLoading(true);
      let apiService = new APICallService(
        AddFeedback,
        inquiresJSON.addInquires(contactData)
      );
      let response = await apiService.callAPI();
      if (response) {
        setContactData({
          name: '',
          email: '',
          phone: '',
          message: staticText,
          phoneCountry: '+255',
        });
        success('Inquiry sent!');
      }
      setLoading(false);
    }
    setValidation(validateTemp);
  };
  const handleOnKeyPress = (event: any) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode === 46) {
      // Prevent the dot (.) character
      event.preventDefault();
    } else if (charCode < 48 || charCode > 57) {
      // Allow only numeric characters (0-9)
      event.preventDefault();
    }
  };
  const handleEmail = (event: any) => {
    let validateTemp = { ...validation };
    if (isValidEmail(event.target.value)) {
      console.log('Email is valid.');
    } else {
      validateTemp.emailRegex = true;
    }
    setValidation(validateTemp);
  };
  const isValidEmail = (email: any) => {
    return emailRegex.test(email);
  };
  return (
    <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center text-left">
      <div className="col-lg-6 ">
        <h1 className="display-1 fs-36">Contact us</h1>
        <p className="lead fs-18 fw-500 text-left mb-8">
          Reach out anytime, we’re here to assist you with anything!
        </p>
        <div className="row g-4">
          {/* /column */}
          <div className="col-lg-6">
            <Form.Control
              className={clsx(
                'form-control ',
                validation.name ? 'border-danger' : ''
              )}
              type="text"
              value={contactData.name}
              placeholder="Name"
              name="name"
              onChange={(e: any) => {
                handleDetailsChange(e.target.name, e.target.value);
              }}
            />
          </div>
          {/* /column */}
          <div className="col-lg-6">
            <InputGroup>
              <InputGroup.Text
                className={clsx(
                  'bg-color-light2 border-end-0 ',
                  validation.email ? 'border-danger' : ''
                )}
                id="basic-addon1"
              >
                +255
              </InputGroup.Text>
              <Form.Control
                className={clsx(
                  'form-control border-start-0 ',
                  validation.email ? 'border-danger' : ''
                )}
                placeholder="Phone number"
                value={contactData.phone}
                name="phone"
                onChange={(e: any) => {
                  handleDetailsChange(e.target.name, e.target.value);
                }}
                onKeyPress={(event: any) => {
                  handleOnKeyPress(event);
                }}
              />
            </InputGroup>
          </div>
          <div className="col-12">
            <Form.Control
              className={clsx(
                'form-control ',
                validation.email || validation.emailRegex ? 'border-danger' : ''
              )}
              type="text"
              placeholder="Email address"
              value={contactData.email}
              name="email"
              onBlur={(e: any) => {
                handleEmail(e);
              }}
              onChange={(e: any) => {
                handleDetailsChange(e.target.name, e.target.value.trim());
              }}
            />
            {validation.emailRegex ? (
              <span className="fs-14 text-danger">
                Please enter valid email
              </span>
            ) : (
              <></>
            )}{' '}
          </div>
          <div className="col-12">
            <Form.Control
              className={clsx(
                'form-control',
                validation.message ? 'border-danger' : ''
              )}
              as="textarea"
              value={contactData.message}
              placeholder="Write you message..."
              style={{ height: 150 }}
              name="message"
              onKeyDown={(event: any) => {
                if (
                  event.keyCode === 8 &&
                  event.target.selectionStart === 0 &&
                  event.target.selectionEnd === 0
                ) {
                  event.preventDefault(); // Prevent backspace key from removing "hii"
                }
              }}
              onChange={(e: any) => {
                handleDetailsChange(e.target.name, e.target.value);
              }}
            />
          </div>
          <div className="col-12 text-left">
            <Button
              type="button"
              className="btn btn-primary btn-send mt-2 mb-10"
              onClick={handleSubmit}
            >
              {!loading && (
                <span className="indicator-label fs-16 fw-bold">Submit</span>
              )}
              {loading && (
                <span
                  className="indicator-progress fs-16 fw-bold"
                  style={{ display: 'block' }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </Button>
          </div>
        </div>
      </div>
      <div className="col-lg-6 ">
        <div className="row g-4">
          <div className="col-lg-12 mx-auto">
            <div className="row g-4">
              <div className="col-lg-6 mb-4 col-md-6">
                <div className="text-center">
                  <img
                    className="img-fluid mb-3"
                    src={IMAGES.location}
                    height={48}
                    width={48}
                    alt=""
                  />
                  <span className="d-block fs-18 fw-600 mb-4">Addresss</span>
                  <p className="fs-22 fw-bolder text-black">
                    Dar es Salaam, Tanzania
                  </p>
                </div>
              </div>
              <div className="col-lg-6 mb-4 col-md-6">
                <div className="text-center">
                  <img
                    className="img-fluid mb-3"
                    src={IMAGES.email}
                    height={48}
                    width={48}
                    alt=""
                  />
                  <span className="d-block fs-18 fw-600 mb-4">Email</span>
                  <p className="fs-22 fw-bolder text-black">info@umart.com</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mx-auto">
            <div className="row g-4">
              <div className="col-lg-6 mb-4">
                <div className="text-center">
                  <img
                    className="img-fluid mb-3"
                    src={IMAGES.call}
                    height={48}
                    width={48}
                    alt=""
                  />
                  <span className="d-block fs-18 fw-600 mb-4">Call us on</span>
                  <p className="fs-22 fw-bolder text-black">+253 345 567 789</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row g-4">
          <div className="col-lg-6 col-md-6 mb-4">
            <div className="text-center">
              <img
                className="img-fluid mb-3"
                src={IMAGES.location}
                height={48}
                width={48}
                alt=""
              />
              <h3 className="fs-18 fw-600 mb-4">Address</h3>
              <p className="fs-22 fw-bolder text-black">
                Dar es Salaam, Tanzania
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 mb-4">
            <div className="text-center">
              <img
                className="img-fluid mb-3"
                src={IMAGES.email}
                height={48}
                width={48}
                alt=""
              />
              <h3 className="fs-18 fw-600 mb-4">Email us</h3>
              <p className="fs-22 fw-bolder text-black">info@umart.com</p>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 mb-4">
            <div className="text-center">
              <img
                className="img-fluid mb-3"
                src={IMAGES.call}
                height={48}
                width={48}
                alt=""
              />
              <h3 className="fs-18 fw-600 mb-4">Call us on</h3>
              <p className="fs-22 fw-bolder text-black">+253 345 567 789</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default Contact;
